import SectionTitle from 'components/global/SectionTitle'
import { Col, Row, Section } from 'components/grid'
import { P } from 'components/typography/Paragraph'
import React, { ReactElement } from 'react'
// @ts-ignore
import Slide from 'react-reveal/Slide'

interface StepItem {
	time: string
	title: string
	description: ReactElement[]
	image: string
	sign: string
}

const steps: StepItem[] = [
	{
		time: '1998 – Present Day',
		title: 'Book Selection',
		description: [
			<P size="md" className="mb-3">
				Unlike traditional publishers, ARC continuously reviews the best books from 250+ publishers worldwide. The ARC team assesses books for quality, content,
				representation, and age appropriateness. With ARC, you can be sure that you are always getting the best books currently available.
			</P>,
			<P size="md" className="mb-3">
				Our in-house publisher, ARC Press®, creates content-rich, engaging science and social studies titles in an assortment of literary genres.This variety fills gaps for
				rigorous, diverse text across a range of complexities. All our ARC Press titles are published in both English and Spanish.
			</P>
		],
		image: '/images/libraries/books-1.png',
		sign: '/images/libraries/road-sign-1.svg'
	},
	{
		time: '1–2 Weeks',
		title: 'Text Complexity Evaluation',
		description: [
			<P size="md" className="mb-3">
				ARC separately evaluates text content and complexity using the IRLA Leveling System to ensure that students of all ages receive high-interest, age-appropriate
				titles.
			</P>
		],
		image: '/images/libraries/leveling.jpg',
		sign: '/images/libraries/road-sign-2.svg'
	},
	{
		time: '1–2 Weeks',
		title: 'Sorting Process',
		description: [
			<P size="md" className="mb-3">
				Once evaluated, books are sorted into collections to meet a wide variety of instructional needs. All ARC collections are balanced to provide:
			</P>,
			<ul className="list-checkmark pl-10 text-gray-500">
				<li className="mb-1">Kid appeal</li>
				<li className="mb-1">Content–rich & knowledge–building texts</li>
				<li className="mb-1">Multiperspective, multicultural texts</li>
				<li>High–quality fiction & nonfiction titles</li>
			</ul>
		],
		image: '/images/libraries/sorting.png',
		sign: '/images/libraries/road-sign-3.svg'
	},
	{
		time: '2–4 Weeks',
		title: 'Your Purchase Order Is Received',
		description: [
			<P size="md" className="mb-3">
				Your purchase order is processed. Books are ordered and collections are assembled.
			</P>
		],
		image: '/images/libraries/processing.jpg',
		sign: '/images/libraries/road-sign-4.svg'
	},
	{
		time: '1–2 Weeks',
		title: 'Classroom Preparation',
		description: [
			<P size="md" className="mb-3">
				Collections are packed and delivered classroom-ready for easy distribution to schools, classrooms, and individual students.
			</P>
		],
		image: '/images/libraries/delivery.png',
		sign: '/images/libraries/road-sign-5.svg'
	}
]

const JourneyOfABook = () => {
	return (
		<Section id="journey-of-a-book" margin="mt-20 md:mt-40" scrollMarginTop="75px">
			<SectionTitle title="What Sets ARC Collections Apart?" />
			<Row>
				<Col>
					<div className="relative h-full overflow-hidden pt-12 pl-10 lg:pl-0">
						<div className="absolute left-5 -mt-14 h-full border-2 border-slate-200 lg:left-1/2"></div>
						{steps.map((step, index) => {
							return (
								<Slide left={index % 2 == 0} right={index % 2 != 0}>
									<Card step={step} index={index} direction={index % 2 == 0 ? 'left' : 'right'} />
								</Slide>
							)
						})}
					</div>
				</Col>
			</Row>
			<Row className="mt-2 lg:mt-6">
				<Col>
					<div>
						<img src="/images/libraries/destination.jpg" alt="destination" />
					</div>
				</Col>
			</Row>
		</Section>
	)
}

export default JourneyOfABook

interface CardProps {
	step: StepItem
	direction: 'left' | 'right'
	index: number
}

const Card = ({ step, direction }: CardProps) => {
	return (
		<div
			className={`mb-8 flex w-full lg:mb-0 lg:-mt-12 ${
				direction === 'left' ? 'flex-row lg:flex-row-reverse' : ''
			} items-center justify-start gap-3 lg:justify-between lg:gap-0`}
		>
			<div className="border-1 hidden w-5/12 lg:block"></div>

			<img src={step.sign} alt={step.title} className="z-20 order-1 -ml-10 h-12 w-12 lg:ml-0 lg:h-24 lg:w-24" />
			<div className="relative order-1 w-full border lg:w-5/12">
				<div>
					<img src={step.image} className="mx-auto" alt={step.title} />
				</div>
				<div className="z-50 rounded-b bg-white p-4 shadow-md">
					<span className="block text-3xl font-semibold text-ab-100">{step.title}</span>
					{step.description.map((element) => element)}
				</div>
				<div className={`absolute top-1/2 hidden lg:block ${direction === 'left' ? '-right-12' : '-left-12'} mt-[2px] h-[2px] w-10 bg-slate-300`} />
			</div>
		</div>
	)
}
