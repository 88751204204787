import LargeForm from 'components/forms/LargeForm'
import Resource from 'components/global/Resource'
import SectionTitle from 'components/global/SectionTitle'
import { Button } from '@arckit/ui'
import { Col, Row, Section } from 'components/grid'
import Layout from 'components/Layout'
import JourneyOfABook from 'components/libraries/JourneyOfABook'
import SubNav from 'components/SubNav'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import { H1 } from 'components/typography/Headings'
import { P } from 'components/typography/Paragraph'

export default function LibrariesPage() {
	const collections = [
		{
			id: 'wide-reading',
			name: 'Wide Reading Classroom Libraries',
			text: 'Each wide reading classroom library is designed to pull every student into reading. Not every reader will like every book, but every reader will find some desirable books in every basket and some new favorite authors, series, genres, and interests to pursue.',
			image: '/images/libraries/wide-reading.png',
			link: '',
			doc: '',
			externalLink: ''
		},
		{
			id: 'text-sets',
			name: 'Thematic Text Sets',
			text: 'Thematic units focused on Social Studies, Science, or a literary genre immerse students in high–information texts and meaningful content that build the prior knowledge needed to be more effective readers and learners.',
			image: '/images/libraries/text-sets.png',
			link: '/themes',
			doc: '',
			externalLink: ''
		},
		{
			id: 'decodables',
			name: 'Decodable Text Anthologies',
			text: "ARC's Decodable Text Anthologies feature highly engaging, content-rich books that provide an additional layer of reading practice. Every text is controlled for the specific Unit, Weekly, and Daily focus grade-level Foundational Skills that are taught and practiced in ARC Core®.",
			image: '/images/libraries/decodables.png',
			link: '',
			doc: 'ARC Decodables Text Anthologies',
			externalLink: ''
		},
		{
			id: 'hook-books',
			name: 'Hook Book Collections',
			text: 'Hook Book Collections offer you the books most enthusiastically read by our most reluctant readers. These collections are carefully curated to include the types of books every classroom library needs to nurture the enjoyment of reading and development of students in each grade.',
			image: '/images/libraries/hook-books.png',
			link: '',
			doc: 'Hook Book Collections',
			externalLink: ''
		},
		{
			id: 'best-books',
			name: 'Best Books Collections',
			text: 'Writer, speaker, and well–respected educator Dr. Pedro Noguera has partnered with ARC to create independent reading collections hand–selected from the best fiction, nonfiction, and poetry available on the publishing market.',
			image: '/images/libraries/best-books.png',
			link: '/best-books-by-pedro-noguera',
			doc: '',
			externalLink: ''
		},
		{
			id: 'be-the-books',
			name: 'Be the Book Collections',
			text: 'Best–selling author and education advocate Dr. Jeffrey Wilhelm partners with ARC in curating classroom libraries to support his research. Dr. Wilhelm has devoted his career to helping teachers engage students with texts. Each collection includes 100 titles hand–selected from 250+ publishers based on the work of Dr. Wilhelm.',
			image: '/images/libraries/be-the-book.png',
			link: '',
			doc: '',
			externalLink: ''
		}
	]

	const subNavItems = [
		{ name: 'Journey of a Book', link: 'journey-of-a-book' },
		{ name: 'Wide Reading', link: 'wide-reading' },
		{ name: 'Thematic Text Sets', link: 'text-sets' },
		{ name: 'Decodable Texts', link: 'decodables' },
		{ name: 'Hook Books', link: 'hook-books' },
		{ name: 'Best Books', link: 'best-books' },
		{ name: 'Be the Book', link: 'be-the-books' },
		{ name: 'ARC Press', link: 'arc-press' }
	]

	return (
		<Layout pageName="Libraries">
			<SubNav items={subNavItems} />
			<Section flatGray>
				<Row middle className="pb-12 pt-12 md:pb-16 md:pt-0">
					<Col width="w-full lg:w-1/2">
						<H1 className="mb-2">Classroom Libraries</H1>
						<P>
							ARC offers a curated collection of texts that build content and vocabulary knowledge through reading, writing, listening, speaking, and language. Each
							text has been hand-selected by our expert staff through a rigorous review that uses content guidelines for age appropriateness. Wherever we've found
							gaps in the market, our in-house publisher ARC Press creates award-winning quality texts in English and Spanish that keep readers informed and engaged.
						</P>
					</Col>
					<Col width="w-full lg:w-1/2" className="hidden lg:block">
						<StaticImage src="../../static/images/libraries/page-hero.png" alt="leveled libraries" />
					</Col>
				</Row>
			</Section>
			<Section id="collections" margin="mt-20" scrollMarginTop="75px">
				<SectionTitle title="Available Collections" />
				<Row>
					{collections.map((collection) => {
						return (
							<Col key={collection.id} width="w-full md:w-1/2" className="mb-6">
								<CollectionCard collection={collection} />
							</Col>
						)
					})}
				</Row>
			</Section>
			<JourneyOfABook />
			<Section id="arc-press" margin="mt-20 md:mt-40" scrollMarginTop="75px">
				<Row middle>
					<Col width="w-full md:w-1/2" className="mb-6 md:mb-0">
						<div className="h-full w-full bg-gray-100 py-16 pl-0 md:pl-10">
							<StaticImage src="../../static/images/arc-press/if-the-shoe-fits-spread.png" placeholder="blurred" alt="if the shoe fits" className="-mr-0 md:-mr-32" />
						</div>
					</Col>
					<Col width="w-full md:w-1/2" className="pl-4 md:pl-32">
						<span className="mt-3 mb-1 inline-block text-3xl font-medium">ARC Press</span>
						<P size="md" className="mb-4">
							With more than 1,200 books written in English and Spanish, ARC Press offers students an abundance of choice. ARC Press books are designed for beginning
							readers and illustrated with bold, engaging images and artwork. Regardless of age or first language, ARC Press books bridge the gap between listening to
							books read by others and reading independently.
						</P>
						<Link to="/arc-press">
							<Button>Learn More</Button>
						</Link>
					</Col>
				</Row>
			</Section>

			<Section margin="mt-20">
				<Row center className="pb-6">
					<Col width="lg:w-1/2">
						<LargeForm pageName="Libraries" />
					</Col>
				</Row>
			</Section>
		</Layout>
	)
}

interface CollectionCard {
	collection: {
		id: string
		name: string
		text: string
		image: string
		link?: string
		doc?: string
		externalLink?: string
	}
}

function CollectionCard({ collection }: CollectionCard) {
	return (
		<div id={collection.id} className="h-full rounded-b-lg border shadow">
			<div className="bg-slate-100 p-8">
				<img src={collection.image} alt="hook books" />
			</div>
			<div className="p-6">
				<span className="mb-3 block text-3xl font-semibold">{collection.name}</span>
				<P size="md" className="mb-3">
					{collection.text}
				</P>
				{collection.link && (
					<Link to={collection.link}>
						<Button>Learn More</Button>
					</Link>
				)}
				{collection.doc && (
					<Resource title={collection.doc} linkOnly>
						<Button>Learn More</Button>
					</Resource>
				)}
				{collection.externalLink && (
					<a href={collection.externalLink} target="_blank">
						<Button>Learn More</Button>
					</a>
				)}
			</div>
		</div>
	)
}
